<template>
<!-- 云展厅 -->
<div v-if="!show_coopimg">
    <div class="CloudBox" id="scroll"  @scroll='showOut' ref="exhibitionScroll">
        <div class="bor-top" v-if="exhibitType == 2"></div>
        <!-- 纯图模式 -->
        <div class="pic-model" style="min-height:95%;" v-if="params.showroom_type =='1'">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
            <van-empty :image="require('@/assets/null.svg')" v-else-if="pictureData.length == 0"/>
            <van-list
            v-else
            v-model="lazyLoading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
            >
                <!-- 外页展示 -->
                <div class="cloudCon outer-page" v-for="(item,index) in pictureData" :key="index" v-if="exhibitType == 1">
                    <div class="cloudTop">
                        <span class="title">{{item.pure_picture_title}}</span>
                        <span class="img-num">({{item.imgNum}})</span>
                    </div>
                    <div class="imgList">
                        <div class="img-box" v-for="imgItem,imgIndex in item.image_id" :key="imgIndex">
                            <img :src="imgItem.url"  alt="" @click="imgBig(item,imgItem.url)" v-if="imgItem.type == 2">
                            <!-- <el-image class="picture" :src="imgItem.img_url"  :preview-src-list="images"></el-image> -->
                            <div class="video-box" v-if="imgItem.type == 3" @click="setVideoShow(imgItem.url)"> 
                                <img :src="imgItem.cover_url" alt="">
                                <div class="mask">
                                    <span>{{imgItem.duration}}</span>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <!-- 内页展示 -->
                <div class="inside-page">
                    <div class="cloudCon" v-for="(item,index) in pictureData" :key="index" v-if="exhibitType == 2" @click="insideLink(item)">
                        <!-- 内页展示 -->
                        <div class="cloudTop">
                            <span class="title">{{item.pure_picture_title}}</span>
                        </div>
                        <div class="imgList"  v-if="exhibitType == 2">
                            <div class="img-box" :class="{'box-bot':visitsSwitch == 0,'non-adaptive':adaptiveSwitch==0}" v-for="imgItem,imgIndex in item.image_id" v-if="imgIndex == 0" :key="imgIndex">
                                <img :src="imgItem.url" alt="" v-if="imgItem.type == 2">
                                <!-- <video v-if="imgItem.type == 2"  @click.stop="insideLink(item)" :src="imgItem.img_url" class="video" :poster="imgItem.img_url+'?vframe/jpg/offset/3'">
                                    您的浏览器不支持 video 标签。
                                </video> -->
                                <div class="video-box" v-if="imgItem.type == 3"> 
                                    <img :src="imgItem.cover_url" alt="">
                                    <div class="mask">
                                        <span>{{imgItem.duration}}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="browse" v-if="visitsSwitch == 1">
                            <img src="../assets/exhibition/browse.svg" alt="">
                            <span>{{item.visits}} 次浏览</span>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <!-- 2D 模板 -->
        <div style="min-height:95%;" class="tow-model" v-if="params.showroom_type =='2'">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
            <van-empty :image="require('@/assets/null.svg')" v-else-if="twoData.length == 0"/>
            <van-list
            v-else
            v-model="lazyLoading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
            >
                <div class="cloudCon" v-for="(item,index) in twoData" :key="index">
                    <div class="cloudTop ">
                        <span class="cloudline" :style="{background:themecolors}"></span>
                        <span>{{item.logo_name}}</span>
                    </div>
                    <div class="imgBox" v-if="item.template_status == 1">
                        <div class="imgbg"  :style="{backgroundImage: `url('${item.cloud_showroom_template_id.template_url}')`,backgroundSize:'100% 100%',}">
                            <img v-for="(logoItem,logoIndex) in item.json_content" :key="logoIndex"  @click="logolink(item,logoItem,logoIndex)" :style="{'width':`${logoItem.width / 2.72}px`,'height':`${logoItem.height / 2.72}px`,'top':`${logoItem.y_axis / 2.72}px`,'left':`${logoItem.x_axis / 2.72}px`}" :src="logoItem.logo_image">
                        </div>
                    </div>
                    <!-- 自定义上传模板 -->
                    <div class="imgBox" v-if="item.template_status == 2">
                        <div class="imgbg"  :style="{backgroundImage: `url('${item.cloud_showroom_template_id.template_url}')`,backgroundSize:'100% 100%',}">
                            <img v-for="(logoItem,logoIndex) in item.json_content" :key="logoIndex"  @click="logolink(item,logoItem,logoIndex)" :style="{'width':`${logoItem.width / 2.72}px`,'height':`${logoItem.height / 2.72}px`,'top':`${logoItem.y_axis / 2.72}px`,'left':`${logoItem.x_axis / 2.72}px`}" :src="logoItem.logo_image">
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <van-image-preview v-model="imgShow" :closeable="true" :showIndicators="false" :startPosition="imgIndex" :images="images" @change="imgChange">
             <template v-slot:cover>
                 <div class="arrow">
                     <div class="left" @click="arrowLeft(imgIndex)">
                         <van-icon name="arrow-left" />
                     </div>
                     <div class="left" @click="arrowRight(imgIndex)">
                         <van-icon name="arrow" />
                     </div>
                 </div> 
             </template>
        </van-image-preview>
        <van-overlay :show="videoShow" @click="setVideoStatus" class="popVideo">
            <div class="closure">
                <i class="el-icon-error"></i>
            </div>
            <video :src="videoUrl" id="video" @click.stop="setVideoStatus" controls="controls">
                您的浏览器不支持 video 标签。
            </video>
        </van-overlay>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
<coopimg v-else :id="coopimgid" :title="coopimgtitle"></coopimg>
</template>
<script>
//https://images.innocomn.com/22ed0202107141458566245.png //默认模板1
//https://images.innocomn.com/3a7d6202107141500174333.png //黄色3
//https://images.innocomn.com/87aed202107141501139276.png //红色5
//https://images.innocomn.com/739fe202107141502175835.png //蓝色2
//https://images.innocomn.com/52a0b202107141502499002.png //绿色4
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import coopimg from './coopimg'
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support,
        coopimg
    },
    data(){
        return{
            cloudData:[],
            iscloud:true,
            isimg:'',//图片地址
            Loading:true,
            pictureData:[],  //纯图数据
            twoData:[],      //2D数据
            page:1,
            page_num:10,
            total:0,
            images: [],   
            imgShow:false,
            imgIndex:'0',
            lazyLoading:false, //懒加载
            finished:false,
            count:0,
            exhibitType:'', //纯图模式展示类型
            isVideo:false,
            videoUrl:'',
            videoShow:false,
            visitsSwitch:0,     //浏览器开关
            adaptiveSwitch:0,   //自适应开关
            coopimgid:'',//点击的id
            coopimgtitle:'',//点击的标题
            scrollTop:0,//滚动距离
        }
    },
    watch:{
         show_coopimg(val){
            if(!val){
                this.$nextTick(()=>{
                    this.$refs.exhibitionScroll.scrollTop = this.scrollTop
                })
            }
         }
    },
    created(){
        this.params = this.$route.query;
        if(sessionStorage.getItem('logolink')){
            this.$store.commit('changge_show_coopimg', true)
        }else{
            this.$store.commit('changge_show_coopimg', false)
        }
    },
    mounted(){
        this.cloud_showroom_show();
        // this.videoPlay()
    },
    computed: {
        ...mapState(['themecolors','show_coopimg'])
    },
    methods:{
        showOut(e){
            this.scrollTop = e.target.scrollTop;
        },
         //视频传参
        setVideoShow(url){
            this.videoShow = true
            this.videoUrl = url
            let video = document.getElementById('video')
            this.$nextTick().then(
                setTimeout(()=>{
                    video.play()
                    if(document.getElementById('musicid')){
                        document.getElementById('musicid').pause()
                        this.$store.commit('changeMusicImg', false)
                    }
                },500)
            )
        },
        setVideoStatus(){
            this.videoShow = false
            let video = document.getElementById('video')
            video.pause()
        },
        arrowLeft(index){
            let idx = index - 1
            if(idx == -1){
                idx = this.images.length -1
            }
            this.imgIndex = idx
            
        },
        arrowRight(index){
            let idx = index + 1
            if(idx == this.images.length){
                idx = 0
            }
            this.imgIndex = idx
        },
        cloud_showroom_show(){
            if(this.params.showroom_type ==1){
                this.images = []
                this.$store.dispatch("get_front_desk_pure_picture",{
                    site_id:this.params.site_id,
                    module_id:this.params.module_id,
                    showroom_type:this.params.showroom_type,
                    page:this.page,
                    page_num:this.page_num
                }).then(res=>{
                    this.Loading = false
                    if(res.data.code == 200){
                        if(res.data.data.module_content.length == 0){
                            this.pictureData = []
                            document.title = res.data.data.module_title
                        }else{
                            if(this.pictureData.length == 0 ){
                                this.pictureData =res.data.data.module_content.data
                            }else{
                                this.pictureData =[...this.pictureData, ...res.data.data.module_content.data] 
                            }
                            document.title = res.data.data.module_title
                            this.total = res.data.data.module_content.total
                            this.count += this.page_num
                            this.lazyLoading = false
                            this.exhibitType = res.data.data.module_content.showroom_type
                            this.visitsSwitch = res.data.data.module_content.visits_switch
                            this.adaptiveSwitch = res.data.data.module_content.adaptive_switch
                            this.pictureData.forEach(item => {
                                item['imgNum'] = item.image_id.length
                                item.image_id.forEach(i=>{
                                    if(i.type == 2){
                                        this.images.push(i.url)
                                    }else{
                                        this.isVideo = true
                                    }
                                })
                            });
                        }
                    }
                })  
            }else if(this.params.showroom_type =='2'){
                this.$store.dispatch("get_two_d_showroom",{
                    site_id:this.params.site_id,
                    module_id:this.params.module_id,
                    showroom_type:this.params.showroom_type,
                    page:this.page,
                    page_num:this.page_num
                }).then(res=>{
                    this.Loading = false
                    if(res.data.code == 200){
                        if(res.data.data.module_content.length == 0){
                            this.twoData = []
                            document.title = res.data.data.module_title
                        }else{
                            if(this.twoData.length == 0){
                                this.twoData = res.data.data.module_content.data
                            }else{
                                this.twoData = [...this.twoData,...res.data.data.module_content.data]
                            }
                            document.title = res.data.data.module_title
                            this.total = res.data.data.module_content.total
                            this.count += this.page_num
                            this.lazyLoading = false
                        }
                    }
                })  
            }
        },
        videoPlay(){
            setTimeout(()=>{
                if(this.isVideo){
                    this.$nextTick().then(()=>{
                        let videoDom = document.getElementsByClassName('video')
                        videoDom.forEach((item,index)=>{
                            item.addEventListener('play',()=>{
                                videoDom.forEach((i,ind)=>{
                                    if(index == ind){
                                        i.play()
                                    }else{
                                        i.pause()
                                    }
                                })
                                if(document.getElementById('musicid')){
                                    document.getElementById('musicid').pause()
                                    this.$store.commit('changeMusicImg', false)
                                }
                                return
                            })
                        })
                    })
                }
            },1000)
        },
        imgChange(){},
        imgBig(item,url){
            // 单组预览
            // this.images = []
            // item.image_id.forEach(i=>{
            //     this.images.push(i.img_url)
            // })
            // this.images.forEach((item,index)=>{
            //     if(item == url){
            //         this.imgIndex = index
            //     }
            // })
            // this.imgShow = true
            // return
            this.imgShow = true
            this.images.forEach((item,index)=>{
                if(item == url){
                    this.imgIndex = index
                }
            })
        },
        //2D模式点击logo跳转
        logolink(item,logoItem,index){
            // 图片模式
            if(logoItem.logo_link_type == '2'){
                logoItem.logo_jump_link.forEach(item=>{
                    item.type = 2
                    item.url = item.image_url
                })
            }
            let params = {
                logolink: logoItem.logo_jump_link,
                picture_title:item.logo_name
            }
            let query = {
                site_url: this.$route.query.site_url,
                singePage: 'singePage',
                backtrack: 'backtrack'
            }  
            if(logoItem.logo_jump_link == ""){
                return
            }
            //内嵌页面
            if(this.$route.query.singePage == 'singePage') {
                //跳转链接
                if(logoItem.logo_link_type == '1'){ 
                    window.location.href=logoItem.logo_jump_link
                }
                //图片模式
                if(logoItem.logo_link_type == '2'){
                    this.$router.push({
                        name:'coopimg',
                        params:params, 
                        query: query
                    })
                }
                //站内页面
                if(logoItem.logo_link_type == '3'){
                    if (logoItem.logo_jump_link.show_grouping == "") {
                        logoItem.logo_jump_link.show_grouping=0
                    } 
                    if(logoItem.logo_jump_link.module_type == '4'){   //新闻
                        this.$router.push({
                            name:'Articlelist',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            },
                            query: query
                        })
                    }else if(logoItem.logo_jump_link.module_type == '5'){   //logo墙
                        this.$router.push({
                            name:'cooperation',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            },
                            query: query
                        })
                    }else if(logoItem.logo_jump_link.module_type == '6'){   //图片集
                        this.$router.push({
                            name:'PhotoGallery',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            },
                            query: query
                        })
                    }else if(logoItem.logo_jump_link.module_type == '7'){   //视频
                        this.$router.push({
                            name:'Video',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            },
                            query: query
                        })
                    }
                }
            }else{
                // 跳转链接
                if(logoItem.logo_link_type == '1'){
                    window.location.href=logoItem.logo_jump_link
                }
                // 图片模式
                if(logoItem.logo_link_type == '2'){
                    this.$router.push({name:'coopimg',params:params})
                }
                // 站内页面
                if(logoItem.logo_link_type == '3'){
                     if (logoItem.logo_jump_link.show_grouping == "") {
                        logoItem.logo_jump_link.show_grouping=0
                    } 
                    if(logoItem.logo_jump_link.module_type == '4'){   //新闻
                        this.$router.push({
                            name:'Articlelist',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            }
                        })
                    }else if(logoItem.logo_jump_link.module_type == '5'){   //logo墙
                        this.$router.push({
                            name:'cooperation',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            }
                        })
                    }else if(logoItem.logo_jump_link.module_type == '6'){   //图片集
                        this.$router.push({
                            name:'PhotoGallery',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            }
                        })
                    }else if(logoItem.logo_jump_link.module_type == '7'){   //视频
                        this.$router.push({
                            name:'Video',
                            params:{
                                site_id: this.params.site_id,
                                show_grouping:logoItem.logo_jump_link.show_grouping
                            }
                        })
                    }
                }
            }
            
            return
            if(this.$route.query.singePage == 'singePage') {
                if(Object.prototype.toString.call(logoItem.logo_jump_link) === '[object String]'){
                    window.location.href=logoItem.logo_jump_link
                }
                if(Object.prototype.toString.call(logoItem.logo_jump_link) === '[object Array]'){
                    this.$router.push({name:'coopimg',params:{
                        logolink: logoItem.logo_jump_link,
                        picture_title:item.logo_name
                    }, 
                    query: {
                        site_url: this.$route.query.site_url,
                        singePage: 'singePage',
                        backtrack: 'backtrack'
                    }})
                }
            }else{
                if(Object.prototype.toString.call(logoItem.logo_jump_link) === '[object String]'){
                    window.location.href=logoItem.logo_jump_link
                }
                if(Object.prototype.toString.call(logoItem.logo_jump_link) === '[object Array]'){
                    this.$router.push({name:'coopimg',params:{
                        logolink: logoItem.logo_jump_link,
                        picture_title:item.logo_name
                    }})
                }
            }
        },
        add_single_image_visits(site_id,id){
            this.$store.dispatch("add_single_image_visits",{
               site_id:site_id,
               id:id
            })
        },
        // 纯图模式内页跳转
        insideLink(item){
            this.add_single_image_visits(item.site_id,item.id)
            this.$store.commit('changge_show_coopimg', true)
            this.coopimgid=item.image_id
            this.coopimgtitle=item.pure_picture_title
            // if(this.$route.query.singePage == 'singePage') {
            //     this.$router.push({name:'coopimg',params:{
            //         logolink: item.image_id,
            //         picture_title:item.pure_picture_title
            //     }, 
            //     query: {
            //         site_url: this.$route.query.site_url,
            //         singePage: 'singePage',
            //         backtrack: 'backtrack'
            //     }})
            // }else{
            //      this.$router.push({name:'coopimg',params:{
            //         logolink: item.image_id,
            //         picture_title:item.pure_picture_title
            //     }})
            // }
        },
        onLoad() {
            if(this.total > this.count ){
                this.lazyLoading = true
                setTimeout(() => {
                    this.page += 1
                    this.cloud_showroom_show()
                }, 1000);
            }else{
                this.finished = true;
            }
        }
    }
}
</script>
<style lang="less" scoped>
@Color:var(--themecolor);
    /deep/.van-empty,/deep/.van-loading{
        position: absolute;
        top: 50%;
        left:50%;
        transform:translate(-50%,-50%);
    }
    /deep/.van-empty{
        width: 100%;
        .van-empty__image{
            width: 300px;
            height: 300px;
        }
    }
    /deep/.pagination{
        margin-top: 60px;
    }
    .page-twoD{
        padding: 0 10px;
    }
    /deep/.van-loading {
        top: 50%;
    }
    .CloudBox::-webkit-scrollbar{
        display: none;
    }
    /deep/.van-pagination{
        .van-pagination__item{
            color:@Color;
        }
    }
    .CloudBox{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        .cloudCon{
            width: 100%;
            margin-top: 10px;
            .cloudTop{
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: space-around;
                padding-left: 15px;
                position: relative;
                .cloudline{
                    display: block;
                    width: 5px;
                    height:30px;
                    position: absolute;
                    left: 0px;
                    top:50%;
                    transform: translateY(-50%);
                }
                span{
                    padding: 0 10px;
                    color: #646566;
                    font-size: .18rem;
                    font-weight: 700;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .imgBox{
                width: 100%;
                height: 220px;
                margin-top: 5px;
            .imgbg{
                // width:290px;
                // height: 170px;
                // margin-left: calc((100% - 290px)/2);
                width:330px;
                height: 220px;
                margin-left: calc((100% - 330px)/2);
                margin-top: 15px;
                position: relative;
                img{
                    position: absolute;
                }
                
                //默认模板
                .onetype{
                    width: 30px;
                    position: absolute;
                    left: 50%;
                    top:24px;
                    max-height: 30px;
                }
                //黄色
                .twotype{
                    width: 24px;
                    position: absolute; 
                    bottom: 14%;
                    left: 50%;
                    max-height: 30px;
                }
                //红色
                .threetype{
                    width: 20px;
                    position: absolute; 
                    bottom: 45%;
                    left: 35%;
                    max-height: 30px;
                }
                //蓝色
                .fourtype{
                    width: 24px;
                    position: absolute; 
                    bottom: 25%;
                    left:14%; 
                    max-height: 30px;
                }
                //绿色
                .fivetype{
                    width: 30px;
                    position: absolute; 
                    bottom: 21%;
                    left:72%;  
                    max-height: 30px;
                }
            }
            }
        }
    }
.pic-model{
    padding: 0 0;
    .cloudCon{
        .cloudTop{
            justify-content: flex-start;
            span{
                padding: 0;
            }
        }
        .imgList{
            margin-top: 10px;
            display: flex;
            justify-content:space-between;
            flex-wrap: wrap;
            .img-box{
                margin-bottom: 2%;
                width: 100%;
                height: auto;
                display: flex;
                .video{
                    width: 100%;
                    object-fit: cover;
                }
                /deep/.el-image{
                    width: 100%;
                    height: auto;
                }
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .outer-page{
        margin-top: 11px;
        padding: 0 1.2%;
        padding-bottom: 16px;
        border-bottom: 6px solid #F7F8FA;
        .cloudTop{
            justify-content: space-between;
            padding:0px 10px;
            align-items: center;
            .title{
                font-size: 15px;
                color: #000;
                width: 50%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .img-num{
                color: #969799;
                font-weight: 400;
                font-size: 14px;
            }
        }
        .imgList{
            margin-top: 11px;
           .img-box{
                width: 49.4%;
                height: 102px;
                overflow: hidden;
                border-radius: 3px;
                margin-bottom: 1%;
                background: #000;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
           }   
        }
        &:nth-last-child(1){
            color: red;
        }
    }
    
    .inside-page{
        display: flex;
        justify-content:space-between;
        flex-wrap: wrap;
        padding: 0px 0px;
        .cloudCon{
            width: 100%;
            margin-top:0px;
            border-bottom:6px solid #F7F8FA;
            padding-bottom: 16px;
            padding: 0 16px 16px;
            .cloudTop{
                height: auto;
                padding-left: 0px;
                align-items: center;
                margin-top: 15px;
                justify-content: space-between;
                .title{
                    font-size: 16px;
                    color: #000;
                    width: 90%;
                }
            }
        }
        .imgList{
            margin-top: 16px;
           .img-box{
                width: 100%;
            //    height: 195px;
                height: auto;
                overflow: hidden;
                border-radius: 6px;
                margin-bottom: 12px;
                // display: flex;
                // justify-content: center;
                // background: #000;
               img{
                   width: 100%;
                   height: 100%;
                   object-fit: scale-down;
               }
               .video-box{
                   .mask{
                        height: 43px;
                        span{
                            font-size: 14px;
                        }
                    }
                }
            }
            .non-adaptive{
                height: 195px;
                display: flex;
                justify-content: center;
                background: #000;
                img{
                    object-fit:cover;
                }
            }
        }
        .browse{
            display: flex;
            align-items: center;
            span{
                font-size: 12px;
                color: #999999;
                margin-left: 10px;
            }
        }
    }
}
/deep/.van-list{
    overflow: hidden;
}
.tow-model{
    .cloudCon{
        margin-bottom: 10px;
        margin-top: 16px;
        .cloudTop {
            justify-content: flex-start;
            margin-left: calc((100% - 330px)/2);
            // margin-left: 22px;
            .cloudline{
                padding: 0px;
            }
            span{
                width: 90%;
                padding: 0px;
            }
           
        }
        .imgBox{
            .imgbg{
                margin-top: 0px;
                overflow: hidden;
            }
        }  
    }
}

.CloudBox{
    /deep/.van-list__finished-text{
        margin-top: 10px;
    }
    /deep/.van-list__loading{
        .van-loading{
            position: relative!important;
        }
    }
}
.video-box{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    cursor: pointer;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .mask{
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 40%;
        display: flex;
        align-items: center;
        padding-right:10px ;
        justify-content: flex-end;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        span{
            font-size: 12px;
            color: #fff;
        }
    }
}
/deep/.van-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.9);
    video{
        width: 100%;
        height: auto;
    }
    .closure{
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        display: flex;
        cursor: pointer;
        .el-icon-error{
            font-size: 24px;
            color: #fff;
        }
    }
}
/deep/.van-image-preview__cover{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    .arrow{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
        .left{
            font-size: 15px;
            background: #c8c9cc;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
        }
    }
}
.bor-top{
    width: 100%;
    height: 6px;
    background: #F7F8FA;
}
.box-bot{
    margin-bottom: 3px!important;
}
</style>

