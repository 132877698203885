<template>
<div>
<div class="content" id="scroll" > 
    <div class="img-list" :class="{'type-nine': displayType==1, 'type-four': displayType==2, 'type-single': displayType==3}" style="min-height:95%;">
        <div class="cloudTop">
            <div class="title">
                <span class="name">{{pictureTitle}}</span>
            </div>
            <div class="switch">
                <van-popover v-model="showPopover" className="item" placement="bottom-end" trigger="click" >
                    <van-grid :border="false" column-num="1" class="switch">
                       <van-grid-item @click="displayType= 1,showPopover = false" class="item-box item-border">
                           <span :class="{'display-type': displayType==1}">宫格</span>
                           <img v-if="displayType!=1" src="../assets/exhibition/nine.svg" alt="">
                           <img v-else src="../assets/exhibition/nine-active.svg" alt="">
                       </van-grid-item>
                       <van-grid-item @click="displayType= 2,showPopover = false" class="item-box item-border">
                           <span :class="{'display-type': displayType==2}">矩阵</span>
                           <img v-if="displayType==2" src="../assets/exhibition/four-active.svg" alt="">
                           <img v-else src="../assets/exhibition/four.svg" alt="">
                       </van-grid-item>
                       <van-grid-item @click="displayType= 3,showPopover = false" class="item-box">
                           <span :class="{'display-type': displayType==3}">单列</span>
                           <img v-if="displayType==3" src="../assets/exhibition/single-active.svg" alt="">
                           <img v-else src="../assets/exhibition/single.svg" alt="">
                       </van-grid-item>
                    </van-grid>
                    <template #reference>
                        <img v-show="!showPopover" src="../assets/exhibition/range.svg" alt="" @click.stop="showType">
                        <img v-show="showPopover" src="../assets/exhibition/range-active.svg" alt="" @click.stop="showType">
                    </template>
                </van-popover>
            </div>
        </div>
        <div class="img-list-box">
            <div class="img-box" v-for="(item,index) in imgdata" :key="index">
                <img :src="item.url" v-if="item.type == 2" @click="imgBig(item.url)">
                <!-- <video v-if="item.type == 2" :src="item.url" class="video" controls="controls" :poster="item.url+'?vframe/jpg/offset/3'">
                    您的浏览器不支持 video 标签。
                </video> -->
                <div class="video-box" v-if="item.type == 3" @click="setVideoShow(item.url)"> 
                    <img :src="item.cover_url" alt="">
                    <div class="mask">
                        <span>{{item.duration}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <van-image-preview v-model="imgShow" :closeable="true" :showIndicators="false" :startPosition="imgIndex" :images="images">
        <template v-slot:cover>
            <div class="arrow">
                <div class="left" @click="arrowLeft(imgIndex)">
                    <van-icon name="arrow-left" />
                </div>
                <div class="left" @click="arrowRight(imgIndex)">
                    <van-icon name="arrow" />
                </div>
            </div>
        </template>
    </van-image-preview>
    <van-overlay :show="videoShow" @click="setVideoStatus" class="popVideo">
        <div class="closure" @click.stop="setVideoStatus">
            <i class="el-icon-error"></i>
        </div>
        <video :src="videoUrl" id="video" controls="controls">
            您的浏览器不支持 video 标签。
        </video>
    </van-overlay>
    <sideBar></sideBar>
    <Support></Support>
</div>
</div>
</template>

<script>
import sideBar from '@/components/sidebar';
import { mapState } from 'vuex'
import Support from '@/components/support';
export default {
    props:['id','title'],
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            imgdata:[],
            pictureTitle:'',
            images:[],
            imgIndex:'',
            imgShow:false,
            isVideo:false,
            showPopover:false,
            displayType:3,
            videoUrl:'',
            videoShow:false,
        }
    },
    computed: {
        ...mapState(['themecolors'])
    },
    created(){
        if(this.id){
            sessionStorage.setItem('logolink', JSON.stringify(this.id))
            sessionStorage.setItem('pictureTitle', JSON.stringify(this.title))
            this.imgdata = this.id
            this.pictureTitle = this.title
            document.title = this.title
            this.imgdata.forEach(item=>{
                if(item.type == 2){
                    this.images.push(item.url)
                }else{
                    this.isVideo = true
                }
            })
        } else {
            this.imgdata = this.$route.params.logolink
            this.pictureTitle = JSON.parse(sessionStorage.getItem('pictureTitle'))
            document.title = this.$route.params.picture_title
            this.imgdata.forEach(item=>{
                if(item.type == 2){
                    this.images.push(item.url)
                }else{
                    this.isVideo = true
                }
            })
        }
        // this.videoPlay()
        this.defaultDisplay()  //初始显示形式
    },
    methods:{
        showType(){
            this.showPopover = !this.showPopover
        },
        // 初始显示形式
        defaultDisplay(){
            let imgNum = this.imgdata.length
            if(imgNum >= 9){
                // this.displayType = 1
                this.displayType = 3
            }else if(imgNum < 9 && imgNum > 4){
                // this.displayType = 2
                this.displayType = 3
            }else if(imgNum <= 4){
                this.displayType = 3
            }
        },
        //视频传参
        setVideoShow(url){
            this.videoShow = true
            this.videoUrl = url
            let video = document.getElementById('video')
            this.$nextTick().then(
                setTimeout(()=>{
                    video.play()
                    if(document.getElementById('musicid')){
                        document.getElementById('musicid').pause()
                        this.$store.commit('changeMusicImg', false)
                    }
                },500)
            )
        },
        setVideoStatus(){
            let video = document.getElementById('video')
            video.pause()
            this.videoShow = false
        },
        imgBig(url){
            this.imgShow = true
            this.images.forEach((item,index)=>{
                if(item == url){
                    this.imgIndex = index
                }
            })
        },
        //图片预览切换按钮
        arrowLeft(index){
            let idx = index - 1
            if(idx == -1){
                idx = this.images.length -1
            }
            this.imgIndex = idx
            
        },
        arrowRight(index){
            let idx = index + 1
            if(idx == this.images.length){
                idx = 0
            }
            this.imgIndex = idx
        },
        videoPlay(){
            setTimeout(()=>{
                if(this.isVideo){
                    this.$nextTick().then(()=>{
                        let videoDom = document.getElementsByClassName('video')
                        videoDom.forEach((item,index)=>{
                            item.addEventListener('play',()=>{
                                videoDom.forEach((i,ind)=>{
                                    if(index == ind){
                                        i.play()
                                    }else{
                                        i.pause()
                                    }
                                })
                                if(document.getElementById('musicid')){
                                    document.getElementById('musicid').pause()
                                    this.$store.commit('changeMusicImg', false)
                                }
                                return
                            })
                        })
                    })
                }
            },1000)
        },
    }
}
</script>
<style scoped lang="scss">
/deep/ .van-popover__content{
    background-color: red!important;
}
.content{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.img-list{
    width: 100%;
    box-sizing: border-box;
    padding: 15px 22px;
    overflow: hidden;
    overflow-y: scroll;
    .cloudTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .title{
            font-size: 16px;
            color: #000;
            font-weight: 700;
            display: flex;
            width: 80%;
            align-items: center;
            .name{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .img-num{
                color: #969799;
                font-weight: 400;
                font-size: 14px;
                margin-left: 10px;
            }
        }
        .switch{
            display: flex;
            /deep/.van-popover__wrapper{
                display: flex;
            }
        }
    }
    .img-box{
        width: 100%;
        height:auto;
        display: flex;
        margin-bottom: 2%;
        img{
            width: 100%;
            height: 100%;
        }
        .video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:nth-last-child(1){
            margin-bottom: 0%;
        }
    }
    
}
.type-nine{
    padding: 16px 0.3% 16px 0;
    .cloudTop{
        padding: 0 16px;
    }
    .img-list-box{
        display: flex;
        flex-wrap: wrap;
        .img-box{
            width: 32.9%;
            // height: 87px;
            height: 69px;
            margin-bottom: 0.4%;
            margin-left: 0.4%;
            border-radius: 2px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            background: #000;
            img{
                // width: 100%;
                // width: auto;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                // object-fit: cover;
            }
        }
    }
}
.type-four{
    padding: 16px 1%;
    .cloudTop{
        padding: 0 16px;
    }
    .img-list-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .img-box{
            // width: 49.5%;
            // height: 120px;
            width: 49.4%;
            height: 102px;
            margin-bottom: 1%;
            border-radius: 3px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            background: #000;
            img{
                // width: 100%;
                // width: auto;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                // object-fit: cover;
            }
        }
    }
}
.type-single{
    padding: 16px 2%;
    .cloudTop{
        padding: 0 16px;
    }
    .img-list-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .img-box{
            width: 100%;
            // height: 220px;
            height: auto;
            margin-bottom: 1.5%;
            overflow: hidden;
            border-radius: 4px;
            // display: flex;
            // justify-content: center;
            // background: #000;
            img{
                // width: 100%;
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }
            .video-box{
                .mask{
                    height: 43px;
                    span{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.video-box{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    cursor: pointer;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .mask{
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 35%;
        display: flex;
        align-items: center;
        padding-right:10px ;
        justify-content: flex-end;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        span{
            font-size: 12px;
            color: #fff;
        }
    }
}
/deep/.van-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.9);
    video{
        width: 100%;
        height: auto;
    }
    .closure{
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        display: flex;
        cursor: pointer;
        z-index: 999;
        .el-icon-error{
            font-size: 24px;
            color: #fff;
        }
    }
}
/deep/.van-image-preview__cover{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    .arrow{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
        .left{
            font-size: 15px;
            background: #c8c9cc;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
        }
    }
}
</style>
<style lang="less">
.van-popover{
    color:#fff;
    width: 60%;
    max-width: 250px;
    .van-popover__arrow{
        color: rgba(0,0,0,.7);
        right: 0!important;
    }
    .van-popover__content{
        background: none;
        .item-box{
            box-sizing: border-box;
            .van-grid-item__content{
                background: rgba(0,0,0,.8); 
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding:14px 16px;
                span{
                    font-size: 16px;
                    color: #fff;
                }
                .display-type{
                    color: #00ccb0;
                }
            }
        }
        .item-border{
            .van-grid-item__content{
                border-bottom: 1px solid #9F9F9F;
            }

        }
    }
}
</style>